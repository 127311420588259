import request from '@/utils/request'

export function add(data) {
    return request({
        url: 'api/evaluation/activity',
        method: 'post',
        data
    })
}

export function del(ids) {
    return request({
        url: 'api/evaluation/activity/',
        method: 'delete',
        data: ids
    })
}

export function edit(data) {
    return request({
        url: 'api/evaluation/activity',
        method: 'put',
        data
    })
}
export function getAuditUserPage() {
    return request({
        url: 'api/evaluation/activity/user',
        method: 'get',

    })
}

export function findById(id) {
    return request({
        url: 'api/evaluation/activity/byId/' + id,
        method: 'get',
    })
}


export default { add, edit, del, getAuditUserPage, findById }
