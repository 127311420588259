<template>
  <div id="bbForm">
    <van-cell-group title="操勤分审核信息">
      <dis-audit ref="disAudit" :app-params="{ coreKey: $route.query.id,  mkCode: 'ZHCP',coreCode:'ATTENDANCE', status:$route.query.status }" />
    </van-cell-group>
    <van-form ref="form" class="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <custom-select ref="xn" disabled v-model="form.xn" placeholder="请选择学年" label="学年" :columns="dict.xj_nj" />
        <custom-select ref="xq" disabled v-model="form.xq" placeholder="请选择学期" label="学期" :columns="dict.xj_xqgl" />
        <custom-select ref="activityType" disabled readonly v-model="form.activityType" placeholder="请选择检查类型" label="检查类型" :columns="dict.evaluation_activity_type" />
        <van-field v-model="form.activityName" disabled placeholder="请填写检查名称" label="检查名称" :readonly="true" :rules="[{ readonly: true  } ]" />
        <custom-select ref="reviewerNo" disabled v-model="form.reviewerNo" placeholder="请选择审核人" labelKey="name" valueKey="id" label="审核人" :columns="auditByInfo" />
        <van-field v-model="form.remark" disabled rows="4" :readonly="true" :rules="[{ readonly: true  } ]" class="textarea-style" placeholder="请填写情况说明" arrow-direction="" label="情况说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <van-cell-group title="附件">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" :preview="true" v-model="fileList" miz="STU_PUNISH_RESOLVE" biz="storage" :multiple="1" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
      <!-- 负责学生-->
      <van-cell-group title="负责学生">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.stuChekInfos" :key="index" :title="item.perName" :value="item.perCode" :name="index">
            <div> <span class="collapse-title">院系:</span><span class="collapse-text">{{item.collName}}</span></div>
            <div> <span class="collapse-title">专业:</span><span class="collapse-text">{{item.specName}}</span></div>
            <div> <span class="collapse-title">班级:</span><span class="collapse-text">{{item.className}}</span></div>
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>
      <van-cell-group title="负责教师" style="margin-bottom:64px">
        <van-collapse v-model="activeNames2">
          <van-collapse-item v-for="item,index in form.staffCheckInfos" :key="index" :title="item.perName" :value="item.perCode" :name="index">
            <div> <span class="collapse-title">院系:</span><span class="collapse-text">{{item.collName}}</span></div>
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>
    </van-form>
  </div>
</template>
<script>

import crudEvaluationActivity from '@/api/modules/evaluation/evaluationActivity'
import fileUpload from "@/components/FileUpload";
import disAudit from "@/view/modules/common/attendanceAudit";

export default {
  name: "EvaluationActivityForm",
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  components: { fileUpload, disAudit },
  data() {
    return {
      form: {
        fileId: null,
        stuChekInfos: [],
        staffCheckInfos: [],
        id: null,
        activityName: null,
        activityType: null,
        activityTypeName: null,
        xn: null,
        xq: null,
        reviewerNo: null,
        reviewerName: null,
        fileId: null,
        approvalStatus: null,
        remark: null,
      },
      activeNames: [],
      activeNames2: [],
      isFamilyAdd: false,
      familyInfoShow: false,
      editIndex: 0,
      loading: false,
      auditByInfo: [],
      search: "",
      search2: "",
      stuData: [],
      refsh: true,
      fileList: [],
      teacherInfoShow: false,
      teacherData: [],
      search: "",
      customRuleFileds: ["xn", "xq", "activityType", "reviewerNo"]
    }
  },
  created() {
    // 或者审核人最小权限
    crudEvaluationActivity.getAuditUserPage().then((res) => {
      this.auditByInfo = res
    })
    if (this.$route.query && this.$route.query.id) {
      crudEvaluationActivity.findById(this.$route.query.id).then((res) => {
        this.form = { ...res }
        if (res.fileId) {
          this.fileList = [res.fileId]
        }
        this.refsh = false
        this.$nextTick((e) => {
          this.refsh = true
        });
      })
    }
  },
  methods: {
    uploadResponse(fileIds) {
      if (fileIds && fileIds[0]) {
        this.form.fileId = fileIds[0]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#bbForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }

  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>